.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.card{
  background: white;
  border-radius: 16px;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  width: 1000px;
  margin-bottom: 60px;
  padding:22px;
  min-height: calc(100% - 100px);
  position: relative;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#myVideo {
  position: absolute;
  z-index: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /*max-width: 100%;*/
  /*min-width: 100%;*/
  /*min-height: 100%;*/
}
html, body,#root{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

